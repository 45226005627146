import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { defaultFormChangeDebounceTime, FormChangeEvent } from '@hq-core/models/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'hq-order-quantity-field',
    templateUrl: './order-quantity-field.component.html',
    styleUrls: ['./order-quantity-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderQuantityFieldComponent implements OnInit, OnChanges, OnDestroy {
    @Input() defaultEmptyValue: number;
    @Input() quantityOverride: number;
    @Input() allowUnsetValues: boolean;
    @Input() hasQuantityWarning: boolean;
    @Input() disabled: boolean;
    @Input() readonly = false;
    @Output() changeValue = new EventEmitter<FormChangeEvent<number>>();
    @Output() blurInput = new EventEmitter<FormChangeEvent<number>>();

    formControl: FormControl;

    private emptyFieldValue: number;
    private currentValue: number;
    private readonly unsubscribe = new Subject<void>();

    ngOnInit(): void {
        this.emptyFieldValue = this.allowUnsetValues ? null : 0;
        if (this.defaultEmptyValue > 0 && !this.allowUnsetValues) {
            this.emptyFieldValue = this.defaultEmptyValue;
        }

        this.currentValue = this.quantityOverride ?? this.emptyFieldValue;
        this.formControl = new FormControl({
            value: this.currentValue,
            disabled: this.disabled
        });

        this.formControl.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe((value: string) => {
                if (!value) {
                    return;
                }

                this.emitValueChange(value);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quantityOverride && !changes.quantityOverride.firstChange) {
            this.currentValue = this.quantityOverride ?? this.emptyFieldValue;
            this.formControl.setValue(this.currentValue, { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onBlur(): void {
        const value = this.formControl.value;
        this.emitValueChange(value, true);
    }

    private emitValueChange(value: string, isBlur = false): void {
        let nextValue = Number.parseInt(value, 10);
        if (Number.isNaN(nextValue) || nextValue < 1) {
            nextValue = this.emptyFieldValue;
        }

        if (nextValue !== this.currentValue || nextValue <= this.emptyFieldValue) {
            const event = new FormChangeEvent<number>({
                value: nextValue ?? 0,
                isValid: this.formControl.valid || this.formControl.disabled
            });

            if (isBlur) {
                this.blurInput.emit(event);
            } else {
                this.changeValue.emit(event);
            }
        }

        this.formControl.setValue(nextValue, { emitEvent: false });
        this.currentValue = nextValue;
    }
}
