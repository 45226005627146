import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StatusCardColor } from '@hq-shared/models/status-card';

@Component({
    selector: 'hq-status-card',
    templateUrl: './status-card.component.html',
    styleUrls: ['./status-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCardComponent implements OnInit, OnChanges {
    @HostBinding('class.status-card') statusCardClass = true;
    @HostBinding('class.actionable') actionableClass: boolean;
    @Input() caption: string;
    @Input() icon: string;
    @Input() items: number | string = '';
    @Input() color: StatusCardColor;
    @Input() subtext: string;
    @Input() canHover: boolean;
    @Input() isActionable: boolean;
    @Output() clickCard = new EventEmitter<void>();

    cardColorClass: string;

    ngOnInit(): void {
        this.actionableClass = this.isActionable;
        this.updateCardColorClass();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.color) {
            this.updateCardColorClass();
        }
    }

    onClickCard(): void {
        this.clickCard.emit();
    }

    private updateCardColorClass(): void {
        this.cardColorClass = this.color ? `background-${this.color}` : '';
    }
}
